import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95vh"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView textCenter column center w="100%" pt="10px" pb="5px">
            <CFView column center ph="15px">
              <CFText color="white" style={{ fontSize: 32 }} raleway bold>
                NOW TAKING
              </CFText>
              <CFText color="white" style={{ fontSize: 32 }} raleway bold>
                ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView mt="10px" mb="10px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="95vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView
            row
            center
            alignCenter
            h="60px"
            w="100%"
            pv="5px"
            maxWidth="1400px"
          >
            <CFView column justifyStart>
              <CFText
                color="white"
                style={{
                  fontSize: 32,
                }}
                raleway
                bold
              >
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView pt="5px" ml="20px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
